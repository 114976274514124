<template>
    <section>
        <modal ref="modalAdvertencia" titulo="¡Aguarda!" center-title :btns="tipo == 1 ? buttons : tipo == 2 ? buttonAsesor : buttonsCobertura" no-aceptar no-cancelar @accion="acciones">
            <div class="row mx-0 justify-center">
                <img :src="url_imge" style="max-width:100%;height:288px;" />
                <div class="col-12 text-general text-center f-15 mt-3">
                    {{
                        tipo == 1
                            ? 'Antes de continuar con el pedido es necesario que completes tus datos de registro.'

                            : tipo == 2
                                ? message
                                : 'Lastimosamente no tenemos cobertura en tu área.'
                    }}
                </div>
            </div>
        </modal>
        <modal-completar-registro ref="modalCompletarRegistro" />
        <modal-chatear-asesor ref="modalChatearAsesor" />
        <modalChat ref="modalChatIcono" :limitar-mensajes="limitar" />
    </section>
</template>

<script>
export default {
    components: {
        modalCompletarRegistro: () => import('./modalCompletarRegistro'),
        modalChatearAsesor: () => import('./modalChatearAsesor')
    },
    sockets:{
        activar_chat(value){
            console.log(value);
            const { tienda } = JSON.parse(value)
            this.limitar = tienda.chat_admin == 2 ? false : true
        }
    },
    props: {
        tipo: {
            type: Number,
            default: 1,
        }
    },
    data(){
        return {
			message: 'Estás a un paso de iniciarte como vendedor, en breve un asesor se pondrá en contacto contigo para activarte e iniciar tus ventas.',
            url_imge: '/img/auth/ops.svg',
            buttons: [
                { texto: 'Cerrar', color: 'bg-white p-1 text-general border-general2', accion: 1 },
                { texto: 'Completar', color: 'bg-general text-white p-1 text-general', accion: 2 }
            ],
            buttonAsesor: [
                {   texto: 'Cerrar', color: 'bg-white p-1 text-general border-general2', accion: 1 },
                { texto: 'Hablar con un asesor', color: 'bg-general text-white p-1 text-general', accion: 3 }
            ],
            buttonsCobertura: [
                { texto: 'Cerrar', color: 'bg-white p-1 text-general border-general2', accion: 1 },
                //{ texto: 'Completar', color: 'bg-general text-white p-1 text-general', accion: 2 }
            ],
            limitar: true,
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAdvertencia.toggle();
        },
        acciones(action){
            switch (action){
            case 1:
                this.$refs.modalAdvertencia.toggle();
                break;
            case 2:
                this.$refs.modalAdvertencia.toggle();
                this.$refs.modalCompletarRegistro.toggle();
                break;
            case 3:
                this.$refs.modalAdvertencia.toggle();
                this.$refs.modalChatIcono.toggle(this.$tienda.id, 3) // 3 = tipo tienda

                break;
            }
        }
    },
	mounted(){
		this.message = `Estás a un paso de iniciarte como ${this.$config.vendedor}, en breve un asesor se pondrá en contacto contigo para activarte e iniciar tus ventas.`
	}
}
</script>
